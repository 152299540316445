import React, { Component } from "react";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo"
import BodyClassName from 'react-body-classname';

class IndexPage extends Component {
  render() {
    const data = this.props.data.allContentfulPost;
    const colors = ["purple", "blue", "green"];
    const rand = Math.floor((Math.random() * 4));
    const color = colors[rand];

    return (
      <BodyClassName className={`index ${color}`}>
        <Layout>
          <SEO title="futurefeed" />
          <div className="archive-wrapper">
            <div className="archive-link">
              <Link to="/archive">
                <div>archive</div><div>archive</div><div>archive</div><div>archive</div><div>archive</div><div>archive</div>
                <div>archive</div><div>archive</div>
              </Link>
            </div>
            <div className="second-archive-link">
              <Link to="/archive">
                <div>archive</div><div>archive</div><div>archive</div><div>archive</div><div>archive</div><div>archive</div>
                <div>archive</div><div>archive</div>
              </Link>
            </div>
          </div>
          <div className="home-thumbs">
            {data.edges.map((post, i) => {
              const oneMonthAgo = new Date();
              oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
              const postDate = new Date(post.node.date);
              const shorttext = post.node.homepageShortText;
              let newtext;
              if (shorttext) {
                newtext = shorttext.toString();
                console.log(newtext);
                newtext = newtext.replace("#", "<span class='hash'></span> ");
                newtext = newtext.replace("No.", "<span class='hash'></span>");
              }

              const titletext = post.node.title;
              let newtitletext;
              if (titletext) {
                newtitletext = titletext.toString();
                newtitletext = newtitletext.replace("#", `<span class='hash'></span> `);
              }
              
              if (+oneMonthAgo < +postDate && post.node.title !== "DONT DELETE THIS POST" && post.node.slug !== "testing-post" && post.node.slug !== "testing-post-2") {
                console.log(post.node.homepageThumbnail)
                return (
                  <div key={i} className="post-thumb">
                    <Link to={`/${post.node.slug}`}>
                      <h3 dangerouslySetInnerHTML={{ __html: newtitletext }} ></h3>
                      {post.node.noContributorsAlternateText &&
                        <p>{post.node.noContributorsAlternateText}</p>
                      }

                      {!post.node.noContributorsAlternateText &&
                        <h5>{post.node.contributor[0].name}</h5>
                      }

                      {!post.node.noContributorsAlternateText && post.node.contributor[1] &&
                        <span>
                          <span className="spacer">&</span>
                          <h5>{post.node.contributor[1].name}</h5>
                        </span>
                      }
                      {post.node.homepageThumbnail &&
                        <div className="image-thumb">
                          <h2>{post.node.displayDate}</h2>
                          <Img fluid={post.node.homepageThumbnail.fluid} alt={post.node.homepageThumbnail.description}/>
                        </div>
                      }
                      {(post.node.homepageShortText && !post.node.homepageShortTextDescription) &&
                        <p dangerouslySetInnerHTML={{ __html: newtext }}></p>
                      }
                      {post.node.homepageShortTextDescription &&
                        <div
                          className="rich-text"
                          dangerouslySetInnerHTML={{ __html: post.node.homepageShortTextDescription.childMarkdownRemark.html }}
                        />
                      }
                      <h4>&#8594;</h4>
                    </Link>
                  </div>
                )
              } else {
                return null;
              }
            })}
          </div>
        </Layout>
      </BodyClassName>
    )
  }
}

export default IndexPage

export const indexQuery = graphql`
  query PostQuery {
    allContentfulPost(sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          slug
          title
          noContributorsAlternateText
          contributor {
            ... on ContentfulContributor {
              name
            }
          }
          displayDate
          date
          homepageShortText
          homepageShortTextDescription {
            childMarkdownRemark {
              html
            }
          }
          homepageThumbnail {
            ... on ContentfulAsset {
              description
              file {
                contentType
                url
              }
            }
            fluid(maxWidth: 1800, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
